import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import {Helmet} from 'react-helmet'
import SimpleReactLightbox from 'simple-react-lightbox'
import Header from './../components/header'
import Footer from './../components/footer'

import './../styles/styles.scss'

const isBrowser = typeof window !== 'undefined'

function handleFirstTab(e) {
  if (e.keyCode === 9) {
    document.body.classList.add('user-is-tabbing')
    if (isBrowser) {
      window.removeEventListener('keydown', handleFirstTab)
      window.addEventListener('mousedown', handleMouseDownOnce)
    }
  }
}

function handleMouseDownOnce() {
  document.body.classList.remove('user-is-tabbing')
  if (isBrowser) {
    window.removeEventListener('mousedown', handleMouseDownOnce)
    window.addEventListener('keydown', handleFirstTab)
  }
}

if (isBrowser) {
  window.addEventListener('keydown', handleFirstTab)
}

export default function Layout({children, pageName, documentTitlePrefix = ''}) {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
      prismicSocialSharing {
        data {
          twitter_title {
            text
          }
          twitter_description {
            text
          }
          twitter_card_image {
            url
          }
          facebook_opengraph_title {
            text
          }
          facebook_opengraph_description {
            text
          }
          facebook_opengraph_image {
            url
          }
          google_site_verification_id {
            text
          }
        }
      }
    }
  `)

  let documentTitle = data.site.siteMetadata.title
  if (documentTitlePrefix) {
    documentTitle = `${documentTitlePrefix} | ${documentTitle}`
  }

  const googleSiteVerificationId = data.prismicSocialSharing.data.google_site_verification_id[0].text
    ? data.prismicSocialSharing.data.google_site_verification_id[0].text
    : ''

  return (
    <div id="top" className={`site-wrapper page--${pageName}`}>
      <Helmet>
        <html lang="en" />
        <title>{documentTitle}</title>
        <meta charSet="utf-8" />
        <meta name="description" content={data.site.siteMetadata.description} />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />

        {/* Google Site Verification ID */}
        <meta name="google-site-verification" content={googleSiteVerificationId} />

        {/* Twitter Sharing Meta Tags */}
        <meta name="twitter:title" content={data.prismicSocialSharing.data.twitter_title[0].text} />
        <meta name="twitter:description" content={data.prismicSocialSharing.data.twitter_description[0].text} />
        <meta name="twitter:image" content={data.prismicSocialSharing.data.twitter_card_image.url} />
        <meta name="twitter:card" content="summary_large_image" />

        {/* Facebook Open Graph Meta Tags */}
        <meta property="og:title" content={data.prismicSocialSharing.data.facebook_opengraph_title[0].text} />
        <meta property="og:site_name" content={data.prismicSocialSharing.data.facebook_opengraph_title[0].text} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://fredcuming.com/" />
        <meta property="og:description" content={data.prismicSocialSharing.data.facebook_opengraph_description[0].text} />
        <meta property="og:image" content={data.prismicSocialSharing.data.facebook_opengraph_image.url} />
        <meta property="fb:app_id" content="1198716157175741" />

        {/* Icons */}
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#3e3e3e" />

        {/* Google Fonts */}
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700&display=swap" rel="stylesheet"></link>
      </Helmet>
      <SimpleReactLightbox>
        <Header title={data.site.siteMetadata.title} pageName={pageName} />
        <main className={`site-main site-main--${pageName}`}>{children}</main>
        <Footer />
      </SimpleReactLightbox>
    </div>
  )
}
