import React from 'react'
import {Link} from 'gatsby'
import styled from 'styled-components'
import Navigation from './../components/navigation'
import Container from './../components/container'

// import headerBackground from './../images/header-bg.jpg'

export default function Header({title, pageName}) {
  return (
    <StyledComponent className={`site-header site-header--${pageName}`}>
      <div className="header-inner">
        <Container className="title-container">
          <Link to="/" className="header-heading-link">
            <h1 className="header-heading">{title}</h1>
          </Link>
        </Container>
        <Navigation title={title} />
      </div>
    </StyledComponent>
  )
}

const StyledComponent = styled.header`
  background-color: #fff;
  margin-bottom: 6vw;
  z-index: 2;

  .page--index & {
    margin-bottom: 0;
  }

  @media (min-width: 700px) {
    margin-bottom: 4.2rem;
  }

  @media (min-width: 1200px) {
    background-color: #fff;
    margin: 0 auto 4rem auto;
    max-width: none;
    padding: 0;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 50;

    &.site-header--work-2000-present,
    &.site-header--work-1980-2000,
    &.site-header--work-1950-1980,
    &.site-header--ra-solo-show,
    &.site-header--public-collections,
    &.site-header--watercolours,
    &.site-header--sketchbooks,
    &.site-header--limited-edition-prints {
      margin-bottom: 0;
    }
  }

  .header-inner {
    @media (min-width: 1200px) {
      align-items: center;
      display: flex;
      flex-direction: row;
      height: 11.3rem;
      justify-content: space-between;
      margin: 0 auto;
      max-width: 147rem;
      padding: 0 5rem;
      width: 100%;
    }
  }

  .title-container {
    @media (min-width: 1200px) {
      margin: 0;
      max-width: none;
      padding: 0;
      width: auto;
    }
  }

  .header-heading-link {
    display: block;
    transform: translateY(-4px);

    /* .page--index & {
      display: none;
      transition: opacity 0.5s linear;

      @media (min-width: 1200px) {
        display: block;
        opacity: 0;
        pointer-events: none;

        body.heading-is-offscreen & {
          opacity: 1;
          pointer-events: all;
        }
      }
    } */
  }

  .header-heading {
    align-items: center;
    border: 0;
    display: inline-block;
    font-size: 18pt;
    font-weight: normal;
    line-height: 1;
    margin: 1rem 0;
    min-height: 3.9rem;
    padding: 1.6rem 0;
    position: relative;
    text-align: left;
    text-transform: uppercase;

    &::after {
      background-color: #909090;
      bottom: 0;
      content: '';
      display: block;
      height: 2px;
      left: 0;
      position: absolute;
      width: 100%;
    }

    @media (min-width: 1200px) {
      margin: 0;
      min-height: 0;
      transform: none;
      transform: translateY(2px);
    }
  }
`
