import React, {useRef} from 'react'
import {Link} from 'gatsby'
import styled from 'styled-components'
import AnimateHeight from 'react-animate-height'
import useOutsideClick from './../hooks/useOutsideClick'
import {NAV_MENU_DESKTOP_BREAKPOINT, SLIDE_DOWN_SUB_NAV_DURATION_MS} from './../constants/constants'

export default function MenuItem({
  label,
  linkTo,
  isSubMenuItem,
  subMenuItems,
  className,
  isExpanded,
  clickHandler,
  clickOutsideHandler,
  subMenuConstant,
}) {
  const menuItemRef = useRef()

  useOutsideClick(menuItemRef, () => {
    const isBrowser = typeof window !== `undefined`
    if (isBrowser) {
      const width = window.innerWidth > 0 ? window.innerWidth : window.screen.width
      if (clickOutsideHandler && width >= NAV_MENU_DESKTOP_BREAKPOINT) {
        clickOutsideHandler(subMenuConstant)
      }
    }
  })

  return (
    <StyledComponent className="menu-item" ref={menuItemRef}>
      {linkTo ? (
        <Link to={linkTo} className={`menu-item-link menu-item-link--${className}`} dangerouslySetInnerHTML={{__html: label}} />
      ) : (
        <>
          <button type="button" className={`menu-item-link menu-item-link--${className}`} onClick={() => clickHandler(subMenuConstant)}>
            <span dangerouslySetInnerHTML={{__html: label}} />
          </button>
          <AnimateHeight className="sub-menu-list__animate-height" duration={SLIDE_DOWN_SUB_NAV_DURATION_MS} height={isExpanded ? 'auto' : 0}>
            <ul className={`sub-menu-list sub-menu-list--${className}`}>
              {subMenuItems.map((item, index) => (
                <MenuItem key={index} label={item.label} linkTo={item.linkTo} isSubMenuItem={true} className={item.className} />
              ))}
            </ul>
          </AnimateHeight>
        </>
      )}
    </StyledComponent>
  )
}

const StyledComponent = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;

  &:last-of-type {
    margin-bottom: 2rem;
  }

  @media (min-width: 1200px) {
    margin: 0 3.5rem 0 0;
    position: relative;

    &:last-child {
      margin-right: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
      margin-right: 3.5rem;
    }
  }

  @media (min-width: 1300px) {
    margin-right: 3.5rem;

    &:last-of-type {
      margin-right: 3.5rem;
    }
  }

  a,
  button {
    background-color: transparent;
    border: 0;
    color: #000;
    display: block;
    font-family: 'Aktifo B Light', sans-serif;
    font-size: 1.35rem;
    font-weight: normal;
    line-height: 1;
    margin: 0;
    padding: 1.8rem 0.5rem;
    position: relative;
    text-transform: uppercase;
    transition: color 0.5s linear;

    &::after {
      background-color: #909090;
      bottom: 0;
      content: '';
      display: block;
      height: 2px;
      left: 0;
      position: absolute;
      transform: scaleX(0);
      transform-origin: top left;
      transition: all 0.25s linear;
      width: 100%;
    }

    &:hover {
      &::after {
        transform: scaleX(1);
      }
    }

    @media (min-width: 1200px) {
      padding: 1.8rem 0.5rem;
    }

    &:hover {
      color: #000;
    }
  }

  .sub-menu-list__animate-height {
    @media (min-width: 1200px) {
      position: absolute;
      top: 100%;
      left: 0;
      padding: 0 0.3rem 0.3rem 0;
    }
  }

  .sub-menu-list {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 3rem 0 0 0;

    @media (min-width: 1200px) {
      align-items: flex-start;
      background-color: #fff;
      /* box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.1); */
      border: 1px solid #cacaca;
      border-top-color: #fff;
      display: flex;
      flex-direction: column;
      padding: 2rem 1rem;
      position: relative;
      z-index: 1000;
    }

    /* &--work {
      @media (min-width: 1200px) {
        min-width: calc(13.4rem + 2rem);
      }
    } */

    &--publications {
      @media (min-width: 1200px) {
        /* min-width: calc(10.6rem + 2rem); */
        min-width: 12.2rem;
      }
    }

    /* &--biography {
      @media (min-width: 1200px) {
        min-width: calc(8.7rem + 2rem);
      }
    } */

    .menu-item {
      margin-bottom: 3rem;

      @media (min-width: 1200px) {
        margin: 0 0 1.5rem 0;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    a {
      color: #6a6a6a;
      font-size: 1.6rem;
      transition: color 0.5s linear;

      &:hover {
        color: #000;
      }
    }
  }
`
