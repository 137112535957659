import React, {useState} from 'react'
import {Link} from 'gatsby'
import styled from 'styled-components'
import AnimateHeight from 'react-animate-height'
import {RemoveScrollBar} from 'react-remove-scroll-bar'
import Hamburger from './hamburger'
import MenuItem from './menu-item'
import Container from './../components/container'
import {SLIDE_DOWN_MOBILE_NAV_DURATION_MS} from './../constants/constants'
import InstagramSvg from './../components/instagram-svg'

export default function Navigation({title}) {
  const [navbarOpen, setNavbarOpen] = useState(false)

  return (
    <StyledComponent>
      <Hamburger className="hamburger" navbarOpen={navbarOpen} clickHandler={setNavbarOpen} />
      {navbarOpen && <RemoveScrollBar />}
      <div className={`menu ${navbarOpen ? 'menu--expanded' : ''}`}>
        <AnimateHeight className="animate-height" duration={SLIDE_DOWN_MOBILE_NAV_DURATION_MS} height={navbarOpen ? 'auto' : 0}>
          <Link to="/">
            <h1 className="mobile-menu-heading">{title}</h1>
          </Link>
          <Container className="menu-container">
            <ul className="menu-list">
              <MenuItem label="Home" linkTo="/" isSubMenuItem={false} className="index" />
              <MenuItem label="Work" linkTo="/work" isSubMenuItem={false} className="work" />
              <MenuItem label="Exhibitions" linkTo="/exhibitions" isSubMenuItem={false} className="exhibitions" />
              <MenuItem label="Publications" linkTo="/publications" isSubMenuItem={false} className="publications" />
              <MenuItem label="Biography" linkTo="/biography" isSubMenuItem={false} className="biography" />
              <MenuItem label="Enquiries" linkTo="/enquiries" isSubMenuItem={false} className="enquiries" />
              <a href="https://www.instagram.com/fred_cuming_ra/" target="_blank" rel="noopener" className="instagram-link" title="Instagram">
                <span className="sr-only">Instagram</span>
                <InstagramSvg fill="#666" className="instagram-svg" />
              </a>
            </ul>
          </Container>
        </AnimateHeight>
      </div>
    </StyledComponent>
  )
}

const StyledComponent = styled.nav`
  .hamburger {
    position: fixed;
    top: 1rem;
    right: 1rem;
    z-index: 2;

    @media (min-width: 1200px) {
      display: none;
    }
  }

  .animate-height {
    @media (min-width: 1200px) {
      height: auto !important;
      overflow: visible !important;
    }

    > div {
      @media (min-width: 1200px) {
        display: block !important;
      }
    }
  }

  .mobile-menu-heading {
    font-family: 'Aktifo B Light', sans-serif;
    font-size: 3rem;
    font-weight: 200;
    text-transform: uppercase;
  }

  .menu-container {
    padding: 0;
    overflow-y: auto;
    background-color: #fff;
    height: calc(100vh - 11rem);

    @media (min-width: 1200px) {
      height: auto;
      background-color: transparent;
      overflow-y: visible;
    }
  }

  .menu {
    bottom: 0;
    height: 100%;
    left: 0;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1;

    &--expanded {
      pointer-events: all;
    }

    @media (min-width: 1200px) {
      position: static;
      pointer-events: all;
    }

    h1 {
      background-color: white;
      justify-content: center;
      margin: 0;
      padding: 7rem 0 0 0;
      text-align: center;

      @media (min-width: 1200px) {
        display: none;
      }
    }
  }

  .menu-list {
    align-items: center;
    background-color: white;
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    pointer-events: all;
    padding: 2rem 0 5rem 0;

    @media (min-width: 1200px) {
      align-items: flex-start;
      background-color: transparent;
      flex-direction: row;
      height: auto;
      padding: 0;
      overflow-y: visible;
    }
  }

  .instagram-link {
    display: block;
    width: 3.4rem;
    height: 3.4rem;
    transform: translateY(1px);
    margin-bottom: 0;

    @media (min-width: 1200px) {
      height: 3.4rem;
      margin-bottom: 0;
      transform: translateY(0.6rem);
      width: 3.4rem;
    }
  }

  .instagram-svg {
    width: 3.4rem;
    height: 3.4rem;
  }
`
