import React from 'react'
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'
import Container from './container'
// import signature from './../images/signature.png'
import signature from './../images/signature-embossed.png'

export default function Footer({pageName}) {
  const date = new Date()

  return (
    <StyledComponent className="site-footer">
      <Container type="wide">
        <Fade>
          <div className="footer-inner">
            <img src={signature} alt="Fred's signature" />
            <p>&copy; {date.getFullYear()} Fred Cuming RA</p>
          </div>
        </Fade>
      </Container>
    </StyledComponent>
  )
}

const StyledComponent = styled.footer`
  .footer-inner {
    font-size: 1.2rem;
    font-weight: 300;
    padding: 0.6rem 1rem;
    text-align: center;

    @media (min-width: 500px) {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 2rem 2rem;
    }

    @media (min-width: 600px) {
      font-size: 1.4rem;
    }
  }

  p {
    position: relative;

    @media (min-width: 500px) {
      &::before {
        content: '';
        display: block;
        background-color: #909090;
        width: 20rem;
        height: 2px;
        top: -3rem;
        right: 0;
        position: absolute;
      }
    }

    @media (min-width: 600px) {
      &::before {
        width: 24rem;
      }
    }
  }

  img {
    height: auto;
    width: 16rem;
    margin-bottom: 2rem;

    @media (min-width: 500px) {
      margin-bottom: 0;
    }

    @media (min-width: 600px) {
      width: 21rem;
    }
  }
`
