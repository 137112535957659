export const NAV_MENU_DESKTOP_BREAKPOINT = 1200
export const SLIDE_DOWN_MOBILE_NAV_DURATION_MS = 600
export const SLIDE_DOWN_SUB_NAV_DURATION_MS = 600
export const HOME_PAGE_SCROLL_DOWN_DURATION_MS = 1000
// export const RESUME_SCROLL_DURATION_MS = 600
// export const WORK_SCROLL_DURATION_MS = 600
export const SCROLL_TO_TOP_DURATION_MS = 1000
// export const NUM_HOMEPAGE_HERO_IMAGES = 7
export const PATH_TO_STATIC_IMAGES = '/static-images/'
export const SHOW_BUY_PRINTS_BUTTONS = true
// export const PURCHASE_VIA_EMAIL = true
// export const PURCHASE_EMAIL_ADDRESS = 'sales@fredcuming.com'
// export const PURCHASE_SUBJECT_LINE = 'Fred Cuming RA - Limited Edition Print'
export const PURCHASE_BODY_TEXT =
  "Hello,\n\nI'm interested in purchasing a limited edition print of TITLEPLACEHOLDER (PRICEPLACEHOLDER).\n\nPlease can you contact me to arrange payment and delivery?\n\nMy contact details are ...\n\n=================================\n\nName: \n\nMobile: \n\nEmail: \n\n=================================\n\nKind regards,\n\n"
export const PURCHASE_BODY_TEXT_TITLE_PLACEHOLDER = 'TITLEPLACEHOLDER'
export const PURCHASE_BODY_TEXT_PRICE_PLACEHOLDER = 'PRICEPLACEHOLDER'
export const PURCHASE_METHOD_EMAIL = 'Email'
export const PURCHASE_METHOD_PAYPAL = 'PayPal'
export const SIMPLE_REACT_LIGHTBOX_OPTIONS = {
  settings: {
    autoplaySpeed: 6000,
    disableKeyboardControls: false,
    disablePanzoom: true,
    disableWheelControls: false,
    hideControlsAfter: 6000,
    lightboxTransitionSpeed: 0.1,
    lightboxTransitionTimingFunction: 'linear',
    overlayColor: '#202020',
    slideAnimationType: 'fade',
    slideSpringValues: [300, 200],
    slideTransitionSpeed: 1,
    slideTransitionTimingFunction: 'linear',
  },
  caption: {
    captionAlignment: 'start',
    captionColor: '#666',
    captionContainerPadding: '0',
    captionFontFamily: 'inherit',
    captionFontSize: 'inherit',
    captionFontStyle: 'inherit',
    captionFontWeight: '200',
    captionTextTransform: 'inherit',
    showCaption: true,
  },
  buttons: {
    backgroundColor: 'rgba(255,255,255,0)',
    iconColor: '#fff',
    iconPadding: '5px',
    showAutoplayButton: false,
    showCloseButton: true,
    showDownloadButton: false,
    showFullscreenButton: false,
    showNextButton: true,
    showPrevButton: true,
    showThumbnailsButton: false,
    size: '50px',
  },
  thumbnails: {
    showThumbnails: false,
    thumbnailsAlignment: 'center',
    thumbnailsContainerBackgroundColor: 'transparent',
    thumbnailsContainerPadding: '0',
    thumbnailsGap: '1px',
    thumbnailsOpacity: 0.4,
    thumbnailsPosition: 'bottom',
    thumbnailsSize: ['100px', '80px'],
  },
  progressBar: {backgroundColor: '#fff', fillColor: '#c6c6c6', height: '1px', showProgressBar: true},
}
export const SIMPLE_REACT_LIGHTBOX_OPTIONS_NO_NAV = {
  ...SIMPLE_REACT_LIGHTBOX_OPTIONS,
  buttons: {
    ...SIMPLE_REACT_LIGHTBOX_OPTIONS.buttons,
    showNextButton: false,
    showPrevButton: false,
  },
}
